angular.module('myApp.controllers')

    .controller('RaincloudIndexMailControll', ['$rootScope', '$scope', 'avLog', 'API_EVENTS', 'SweetAlert', 'blockUI', 'base64', 'ContactService', '$translate', 'ErrorFieldService', '$window',
        function ($rootScope, $scope, avLog, API_EVENTS, SweetAlert, blockUI, base64, ContactService, $translate, ErrorFieldService, $window) {


            var logger = avLog.getLogger('RaincloudIndexMailControll');
            logger.info('page loaded');

            //避免第一次整頁載入page loaded裡的初始化讀取二次
            $scope.waitingUpdateUserData = false;

            $scope.contact = {
                type: '0',
                name: null,
                
                mobilePhone: null,
                company: null,
                jobTitle: '未填寫',
                email: null,
                title: '評估通知:',
               
                content: null, //base64 encode

                //客製欄位
                enquires:'產品諮詢',
            };

            /***
             *     /$$    /$$  /$$$$$$  /$$       /$$$$$$ /$$$$$$$ 
             *    | $$   | $$ /$$__  $$| $$      |_  $$_/| $$__  $$
             *    | $$   | $$| $$  \ $$| $$        | $$  | $$  \ $$
             *    |  $$ / $$/| $$$$$$$$| $$        | $$  | $$  | $$
             *     \  $$ $$/ | $$__  $$| $$        | $$  | $$  | $$
             *      \  $$$/  | $$  | $$| $$        | $$  | $$  | $$
             *       \  $/   | $$  | $$| $$$$$$$$ /$$$$$$| $$$$$$$/
             *        \_/    |__/  |__/|________/|______/|_______/ 
             *                                                     
             *                                                     
             *                                                     
             *     /$$$$$$$$ /$$$$$$$$ /$$   /$$ /$$$$$$$$         
             *    |__  $$__/| $$_____/| $$  / $$|__  $$__/         
             *       | $$   | $$      |  $$/ $$/   | $$            
             *       | $$   | $$$$$    \  $$$$/    | $$            
             *       | $$   | $$__/     >$$  $$    | $$            
             *       | $$   | $$       /$$/\  $$   | $$            
             *       | $$   | $$$$$$$$| $$  \ $$   | $$            
             *       |__/   |________/|__/  |__/   |__/            
             *                                                     
             *                                                     
             *                                                     
             */
            $scope.setInvalidTextOfContactName = function (invalidText) {
                logger.debug('setInvalidTextOfContactName: ' + invalidText);
                $scope.invalidTextOfContactName = invalidText;
            };
            $scope.setInvalidTextOfContactJobTitle = function (invalidText) {
                $scope.invalidTextOfContactJobTitle = invalidText;
            };
            $scope.setInvalidTextOfContactCompany = function (invalidText) {
                $scope.invalidTextOfContactCompany = invalidText;
            };
            $scope.setInvalidTextOfStoreName = function (invalidText) {
                $scope.invalidTextOfStoreName = invalidText;
            };
            $scope.setInvalidTextOfContactTitle = function (invalidText) {
                $scope.invalidTextOfContactTitle = invalidText;
            };
            $scope.setInvalidTextOfContactDesc = function (invalidText) {
                $scope.invalidTextOfContactDesc = invalidText;
            };


            /***
             *     /$$$$$$$$ /$$    /$$ /$$$$$$$$ /$$   /$$ /$$$$$$$$
             *    | $$_____/| $$   | $$| $$_____/| $$$ | $$|__  $$__/
             *    | $$      | $$   | $$| $$      | $$$$| $$   | $$   
             *    | $$$$$   |  $$ / $$/| $$$$$   | $$ $$ $$   | $$   
             *    | $$__/    \  $$ $$/ | $$__/   | $$  $$$$   | $$   
             *    | $$        \  $$$/  | $$      | $$\  $$$   | $$   
             *    | $$$$$$$$   \  $/   | $$$$$$$$| $$ \  $$   | $$   
             *    |________/    \_/    |________/|__/  \__/   |__/   
             *                                                       
             *                                                       
             *                                                       
             */


            $rootScope.$on(API_EVENTS.updateUserData, function (event, data) {
                logger.debug('API_EVENTS.updateUserData');
                $scope.waitingUpdateUserData = true;
                $scope.init();
            });

            /***
             *     /$$$$$$$   /$$$$$$  /$$$$$$$$ /$$$$$$$$ /$$$$$$$  /$$$$$$  /$$$$$$  /$$   /$$ /$$$$$$$$ /$$$$$$$
             *    | $$__  $$ /$$__  $$|__  $$__/| $$_____/| $$__  $$|_  $$_/ /$$__  $$| $$  /$$/| $$_____/| $$__  $$
             *    | $$  \ $$| $$  \ $$   | $$   | $$      | $$  \ $$  | $$  | $$  \__/| $$ /$$/ | $$      | $$  \ $$
             *    | $$  | $$| $$$$$$$$   | $$   | $$$$$   | $$$$$$$/  | $$  | $$      | $$$$$/  | $$$$$   | $$$$$$$/
             *    | $$  | $$| $$__  $$   | $$   | $$__/   | $$____/   | $$  | $$      | $$  $$  | $$__/   | $$__  $$
             *    | $$  | $$| $$  | $$   | $$   | $$      | $$        | $$  | $$    $$| $$\  $$ | $$      | $$  \ $$
             *    | $$$$$$$/| $$  | $$   | $$   | $$$$$$$$| $$       /$$$$$$|  $$$$$$/| $$ \  $$| $$$$$$$$| $$  | $$
             *    |_______/ |__/  |__/   |__/   |________/|__/      |______/ \______/ |__/  \__/|________/|__/  |__/
             *
             *
             */


            $scope.format = 'yyyy-MM-dd';
            $scope.dateOptions = {
                'year-format': 'yyyy-MM-dd',
                'starting-day': 1
            };
            $scope.monthFormat = 'yyyy/MM';
            $scope.datepickerMode = 'month';
            $scope.monthDateOptions = {
                datepickerMode: 'month',
                minMode: 'month'
            };

            // Disable weekend selection
            $scope.disabled = function (date, mode) {
                logger.debug('disabed datepicker');
                return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
            };
            $scope.ismeetdate = {
                opened: false
            };

            /***
             *     /$$$$$$$$ /$$      /$$  /$$$$$$  /$$$$$$ /$$      
             *    | $$_____/| $$$    /$$$ /$$__  $$|_  $$_/| $$      
             *    | $$      | $$$$  /$$$$| $$  \ $$  | $$  | $$      
             *    | $$$$$   | $$ $$/$$ $$| $$$$$$$$  | $$  | $$      
             *    | $$__/   | $$  $$$| $$| $$__  $$  | $$  | $$      
             *    | $$      | $$\  $ | $$| $$  | $$  | $$  | $$      
             *    | $$$$$$$$| $$ \/  | $$| $$  | $$ /$$$$$$| $$$$$$$$
             *    |________/|__/     |__/|__/  |__/|______/|________/
             *                                                       
             *                                                       
             *                                                       
             */
            $scope.testMail = function () {
                logger.debug('testMail');
                var productionMode = $window.MYAPP.production;
                if (productionMode) {
                    $scope.contact = {
                        type: '0',
                        name: '蔡大痣',
                        mobilePhone: '056324803',
                        // '0963009935',
                        company: '鯊客科技',
                        jobTitle: '技術顧問',
                        email: 'bigd@sharktech.tw',
                        title: 'email測試',
                        content: null //base64 encode
                    };
                }
            };

            $scope.setEnquire = function(type){
                logger.debug('setEnquire:' + type);
                $scope.contact.enquires = type;
            };

            $scope.setContactUsEmail = function (contact) {
                logger.debug('setContactUsEmail');

                blockUI.start();
                var postBody = {
                    name: contact.name,
                    mobilePhone: contact.mobilePhone,
                    company: contact.company,
                    jobTitle: contact.jobTitle,
                    email: contact.email,
                    title: contact.title,
                    content: null
                };

             
                if (contact.content == '' || contact.content == null) {

                } else {

                    description = contact.content;
                }


                var contentOfPostBody = {
                    description: description,
                    enquires:contact.enquires,
                    
                };

                logger.debug(contentOfPostBody);

                // if (typeof ($window.ga) != 'undefiend') {
                //     $window.ga('send', {
                //         hitType: 'event',
                //         eventCategory: '聯絡表單',
                //         eventAction: 'contact-page-寄送前-資料保存',
                //         //從哪來
                //         eventLabel: contentOfPostBody
                //     });    
                // }
              
                contentOfPostBody = base64.encode(angular.toJson(contentOfPostBody));
                // console.log(postBody);
                postBody.content = contentOfPostBody;
                // console.log(postBody);
                ContactService.create(
                    postBody,
                    function (data, status) {
                        blockUI.stop();

                        SweetAlert.swal({
                                // title: '信件已發送',
                                // text: '你的信件已發送，請等候回覆',
                                title: $translate.instant('GLOBAL.SA_CONTACT_TITLE_SUC'),
                                text: $translate.instant('GLOBAL.SA_CONTACT_DESC_SUC'),
                                type: 'success',
                                showCancelButton: false,
                                confirmButtonText: $translate.instant('GLOBAL.OK'),
                                closeOnConfirm: true
                            },
                            function () {
                               
                               
                            });
                    },
                    function (data, status) {
                        blockUI.stop();
                        if (status == 500) {

                            SweetAlert.swal(
                                // '信件無法發送',
                                $translate.instant('GLOBAL.SA_CONTACT_TITLE_ERR'),
                                $translate.instant('SYSTEM_INFO.STATUS_500_DETAIL', {
                                    err: data.message
                                }),

                                'error');

                        } else if (status == 400) {
                            SweetAlert.swal(
                                // '信件無法發送',
                                $translate.instant('GLOBAL.SA_CONTACT_TITLE_ERR'),
                                $translate.instant('SYSTEM_INFO.STATUS_400_DETAIL', {
                                    err: ErrorFieldService.parse(data)
                                }),
                                'error');
                        } else {
                            SweetAlert.swal(
                                // '信件無法發送',
                                $translate.instant('GLOBAL.SA_CONTACT_TITLE_ERR'),
                                data.message,
                                'error');
                        }
                    });

            };


            /***
             *     /$$$$$$ /$$   /$$ /$$$$$$ /$$$$$$$$
             *    |_  $$_/| $$$ | $$|_  $$_/|__  $$__/
             *      | $$  | $$$$| $$  | $$     | $$   
             *      | $$  | $$ $$ $$  | $$     | $$   
             *      | $$  | $$  $$$$  | $$     | $$   
             *      | $$  | $$\  $$$  | $$     | $$   
             *     /$$$$$$| $$ \  $$ /$$$$$$   | $$   
             *    |______/|__/  \__/|______/   |__/   
             *                                        
             *                                        
             *                                        
             */

            $scope.init = function () {

            };

            $scope.pageLoad = function () {
                if (!$scope.waitingUpdateUserData) {
                    $scope.init();
                } else {
                    logger.debug('waiting update user data event');
                }
            };

            $scope.pageLoad();


        }
    ]);